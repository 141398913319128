















































































































































































































































































// @ts-nocheck
import utils from '@/utils/utils'
import Vue from 'vue'
// @ts-ignore
import { v4 as uuid } from 'uuid'
// @ts-ignore
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'
import parts, { IPartsListItem } from './parts'
import { calculateShipping } from './util'
import { EShippingCost } from './types'

//TYPESCRIPT INTEGRATION

interface IItem extends IPartsListItem {
  shipping?: number
}
interface IInstallationItem extends IPartsListItem {
  sentry: string
}

const props = {
  plan: {
    type: Object,
    default: () => {}
  },
  actionColumn: {
    type: Boolean,
    default: true
  },
  priceData: {
    type: Object,
    default: () => parts
  }
}

export default Vue.extend({
  name: 'PlanPartsList',
  props,
  data: () => ({
    yearOneAddons: [] as IItem[],
    yearTwoAddons: [] as IItem[],
    selectedAddon: [] as IItem[],
    taxPercent: 0, //as 10% or 50% etc
    showAddonSelect: false,
    customAddon: false,
    customAddonData: {
      price: null,
      name: '',
      shipping: 0,
      part_no: ''
    },
    //define row headers
    headers: [
      {
        text: 'Item',
        align: 'left',
        sortable: false,
        value: 'name'
      },
      // {
      //   text: 'Shipping',
      //   align: 'left',
      //   sortable: false,
      //   value: 'name'
      // },
      {
        text: 'Part No.',
        align: 'left',
        sortable: false,
        value: 'part_no'
      }
    ]
  }),
  methods: {
    formatMoney: utils.formatMoney,
    calculateShipping: calculateShipping,
    itemShippingCost(item: IItem): number | undefined {
      const custom = [
        { name: 'DroneSentry X', cost: 5000 },
        { name: 'DroneSentry X - Detect only', cost: 5000 },
        { name: 'GPS Module', cost: 0 }
      ]
      if (item.key === undefined) return 0
      //find item in parts
      const matchingItem: IItem = parts[item.key]
      const customItem = custom.find(i => i.name === matchingItem.name)

      const CUSTOM_COST = customItem ? customItem.cost : EShippingCost.DEFAULT
      //if item is sub OR item key is in exclude array then no shipping
      if (matchingItem.is_subscription) {
        return (matchingItem.shipping = EShippingCost.NONE)
      } else if (customItem) {
        return (matchingItem.shipping = CUSTOM_COST)
      } else {
        return (matchingItem.shipping = EShippingCost.DEFAULT)
      }
    },
    actionItem(item: IInstallationItem) {
      if (item.type === 'cn') {
        //change the cn type of the plan
        this.plan.site_plan.computenode_type === 'cloud'
          ? (this.plan.site_plan.computenode_type = 'prem')
          : (this.plan.site_plan.computenode_type = 'cloud')

        //emitter
        this.$emit(
          'setCNType',
          this.plan.site_plan.computenode_type === 'prem' ? 'cloud' : 'prem'
        )
      } else {
        const installations = this.plan.site_plan.installations
        if (item.type === 'sentry') {
          installations.splice(item.sentry, 1)
        }
        //REMOVING ITEMS
        else {
          //if the item is an addon item
          if (item.addon) {
            //get addon id
            let addonId = item.id
            //calculate the addon to remove
            const addOnToRemove = this.yearOneItems
              .map((x: IItem) => {
                return x.id
              })
              .indexOf(addonId)
            //remove addon from list logic
            this.yearOneItems.splice(addOnToRemove, 1)
          } else {
            let sensorId = item.sensor
            const sensors = installations[item.sentry].sensors[item.type]
            if (item.subtype) {
              const subSensors = sensors.filter(
                (rf: any) => rf.model === item.subtype
              )

              sensorId = sensors.findIndex(
                (s: any) => s === subSensors[item.sensor]
              )
            }
            sensors.splice(sensorId, 1)
          }
        }
      }
    },

    //REMOVING ITEMS END
    pushItem(items: IItem[], item: IItem, inactiveItems: IItem[]) {
      items.push({
        ...this.priceData[item],
        id: uuid(),

        part_no: inactiveItems.includes(item)
          ? 'incl.'
          : this.priceData[item]?.part_no || `internal error (${item})`
      })
    },
    handlePushAddon() {
      const { name, is_subscription } = this.selectedAddon
      if (this.customAddon) return this.handlePushCustomAddon()
      this.selectedAddon.id = uuid()
      this.selectedAddon.shipping = this.itemShippingCost(this.selectedAddon)
      if (!!name) {
        if (is_subscription) {
          this.yearTwoAddons.push(this.selectedAddon)
        } else {
          this.yearOneAddons.push(this.selectedAddon)
        }
      }
      this.showAddonSelect = !this.showAddonSelect
    },
    handlePushCustomAddon() {
      const { name, price, shipping, part_no } = this.customAddonData
      const id = uuid()
      if (name && price && part_no) {
        this.yearOneItems.push({
          name: name,
          price: parseInt(price),
          id,
          addon: true,
          key: name + id,
          shipping: parseInt(shipping),
          part_no,
          custom: true
        })
        this.customAddonData = {
          price: null,
          name: '',
          part_no: '',
          shipping: 0
        }
      } else {
        alert('Please enter name, price and part number')
      }
    },
    escapeCSVText(text: string) {
      return text.indexOf(',') >= 0 ? `"${text}"` : text
    },
    exportCSV() {
      //define row headers
      const rows = [
        [
          'Installation Name',
          'Part Name',
          'Part Number',
          this.isDRO ? 'Price (USD)' : null,
          'Information'
        ].filter(v => v)
      ]
      const exportFilename = `${this.plan.name}.csv`
      let installationName
      rows.push(
        ...this.items
          .map(i => {
            if (i.class === 'category') installationName = i.name
            else
              return [
                installationName,
                this.escapeCSVText(i.name),
                i.part_no,
                this.isDRO ? i.price : 'na',
                this.escapeCSVText(i.info || '')
              ].filter(v => v !== 'na')
          })
          .filter(i => i)
      )

      const data = rows.map(e => e.join(',')).join('\n')
      const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' })
      //IE11 & Edge
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(csvData, exportFilename)
      } else {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(csvData)
        link.setAttribute('download', exportFilename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    reduceInstallationToSensors(sensors: { [x: string]: any[] }) {
      return Object.keys(sensors || {}).reduce((a, k) => {
        if (k === 'rf_sensors') {
          const rftwos = sensors[k].filter(
            rf =>
              rf.model === 'rf_two' || rf.model === 'rf_one' || !rf.model
          )
          const rfpatrols = sensors[k].filter(rf => rf.model === 'rf_patrol')
          if (rftwos.length) Object.assign(a, { rf_two: rftwos.length })
          if (rfpatrols.length)
            Object.assign(a, { rf_patrol: rfpatrols.length })
        } else if (k === 'dsx_sensors') {
          const dsxrx = sensors[k].filter(
            dsx => dsx.model === 'dsx_direct_no_jam'
          )
          const dsx = sensors[k].filter(dsx => dsx.model === 'dsx_direct')

          if (dsxrx.length) {
            Object.assign(a, { dsx_direct_no_jam: dsxrx.length })
          }
          if (dsx.length) {
            Object.assign(a, { dsx_direct: dsx.length })
          }
        } else {
          Object.assign(a, { [k]: sensors[k]?.length || 0 })
        }
        return a
      }, {})
    },
  },
  computed: {
    ...mapGetters('maps', ['exporting']),
    ...mapGetters('users', ['isDRO']),
    pdfData() {
      const yearOneItemsPdf = []
      const yearTwoItemsPdf = []

      const shippingTotal = this.shippingTotalYearOneRaw || 0
      this.yearOneItems.map(item => {
        let qty = this.yearOneItems.filter(i => i.part_no === item.part_no)
          .length

        let existing = yearOneItemsPdf.find(i => i[1] === item.part_no)
        if (item.price && !existing) {
          //dont push subscription to yearOne as its included in price

          if (!item.is_subscription) {
            yearOneItemsPdf.push([
              qty,
              item.part_no,
              item.name.replace(/#\d/, ''),
              utils.formatMoney(item.price, 0),
              utils.formatMoney(item.price * qty, 0)
            ])
          }
        }
      })

      //original item list which also includes subscriptions
      //we map through this in order to add subscriptions to the year 2part

      // remove dupe subscription items for year two,
      // since subscriptions are multiplied by number of sensors below
      const filteredYearTwoItems = []
      this.yearTwoItems.forEach(item => {
        if (item.is_subscription) {
          if (!filteredYearTwoItems.find(i => i.part_no === item.part_no)) {
            filteredYearTwoItems.push(item)
          }
        } else {
          filteredYearTwoItems.push(item)
        }
      })

      filteredYearTwoItems.forEach(item => {
        if (item.is_subscription) {
          let qty = this.yearTwoItems.filter(i => i.part_no === item.part_no)
            .length

          yearTwoItemsPdf.push([
            qty,
            item.part_no,
            item.name.replace(/#\d/, ''),
            utils.formatMoney(item.price, 0),
            utils.formatMoney(item.price * qty, 0)
          ])
        }
      })

      //shipping will be taxed as well
      const year1Tax = Math.round(
        (this.priceSumYearOneRaw + shippingTotal) * (this.taxPercent / 100)
      )
      const year2Tax = Math.round(
        this.priceSumYearTwoRaw * (this.taxPercent / 100)
      )
      //add sub total
      yearOneItemsPdf.push(['', '', '', 'Sub Total', this.priceSumYearOne])

      //add shipping
      if (shippingTotal) {
        yearOneItemsPdf.push([
          '',
          '',
          '',
          'Shipping',
          utils.formatMoney(shippingTotal, 0)
        ])
      }
      if (year1Tax) {
        //add taxes
        yearOneItemsPdf.push([
          '',
          '',
          '',
          `Tax ${this.taxPercent}%`,
          utils.formatMoney(year1Tax, 0)
        ])
      }

      if (year2Tax) {
        //add sub total
        yearTwoItemsPdf.push(['', '', '', 'Sub Total', this.priceSumYearTwo])
        //add taxes
        yearTwoItemsPdf.push([
          '',
          '',
          '',
          `Tax ${this.taxPercent}%`,
          utils.formatMoney(year2Tax, 0)
        ])
      }

      //add totals
      yearOneItemsPdf.push([
        '',
        '',
        '',
        `Total`,
        utils.formatMoney(year1Tax + this.priceSumYearOneRaw + shippingTotal, 0)
      ])
      yearTwoItemsPdf.push([
        '',
        '',
        '',
        `Total`,
        utils.formatMoney(year2Tax + this.priceSumYearTwoRaw, 0)
      ])

      //totals
      const pdfObject = {
        year1: yearOneItemsPdf,
        year2: yearTwoItemsPdf
      }

      return pdfObject
    },
    shipping(item) {
      return calculateShipping(item)
    },
    addonParts() {
      const addonsArr = []
      //create array from object
      for (const [key, value] of Object.entries(parts)) {
        addonsArr.push({
          key,
          name: value.name,
          price: value.price,
          addon: value.addon,
          is_subscription: value.is_subscription,
          part_no: value.part_no
        })
      }
      return addonsArr.filter(part => part.addon === true)
    },
    pdfDataChanged() {
      const {
        yearOneItems,
        taxPercent,
        yearTwoItems,
        priceSumYearTwoRaw,
        priceSumYearOneRaw
      } = this
      return {
        yearOneItems,
        yearTwoItems,
        priceSumYearTwoRaw,
        priceSumYearOneRaw,
        taxPercent
      }
    },
    //not formatted
    priceSumYearOneRaw() {
      return this.yearOneItems.reduce((s, v) => (s += v['price'] || 0), 0)
    },
    priceSumYearOne() {
      return this.formatMoney(
        this.yearOneItems.reduce((s, v) => (s += v['price'] || 0), 0),
        0
      )
    },
    //not formatted
    priceSumYearTwoRaw() {
      return this.yearTwoItems
        .filter(i => i.is_subscription)
        .reduce((s, v) => (s += v['price'] || 0), 0)
    },
    //calc of subscription costs
    priceSumYearTwo() {
      return this.formatMoney(
        this.yearTwoItems
          .filter(i => i.is_subscription)
          .reduce((s, v) => (s += v['price'] || 0), 0),
        0
      )
    },
    shippingTotalYearOne() {
      return this.formatMoney(
        this.yearOneItems.reduce((s, v) => (s += v['shipping'] || 0), 0),
        0
      )
    },
    shippingTotalYearOneRaw() {
      return this.yearOneItems.reduce((s, v) => (s += v['shipping'] || 0), 0)
    },
    yearOneItems() {
      return [
        ...this.items.filter(item => {
          return !item.is_subscription
        }),
        // ...this.yearOneAddons
      ]
    },
    yearTwoItems() {
      return [
        ...this.items.filter(item => {
          return item.is_subscription
        }),
        ...this.yearTwoAddons
      ]
    },
    items() {
      let items = []
      let nbSensors = 0
      let nbSentries = this.parsedPlan.length
      let hasComputeNode = false
      // let hasComputeNodeCase = false
      // let isRfTracker = false
      const CNType = get(this.plan, 'site_plan.computenode_type', 'prem')
      for (let sentryId in this.parsedPlan) {
        items.push({
          type: 'sentry',
          name: this.plan.site_plan.installations[sentryId].name,
          sentry: sentryId,
          class: 'category',
          id: uuid()
        })
        let sensors = this.parsedPlan[sentryId]
        let sensorTypes = Object.keys(sensors)
        let inactiveSensors = []
        let omittedItems = []
        omittedItems.push('dronecannon_psu') //remove all psu and add one per installation if cannon present

        //Remove radar brackets if rf_two is added
        if (sensorTypes.includes('rf_two') && sensors.rf_two)
          omittedItems.push('radars_brackets')

        let mSensorsSentry = 0
        for (let sensorType of sensorTypes) {
          //sensors exist
          const mSensors = sensors[sensorType] > 1

          //part data
          const partData = this.priceData[sensorType]

          //add sensor types to nbSensors
          nbSensors += sensors[sensorType]
          mSensorsSentry += sensors[sensorType]

          //for each of the sensor types, push the information of the sensor to the items array
          for (let i = 0; i < sensors[sensorType]; i++) {
            items.push({
              ...partData,
              name: `${partData?.name}${mSensors ? ' #' + (i + 1) : ''}`,
              sentry: sentryId,
              sensor: i,
              part_no: inactiveSensors.includes(sensorType)
                ? 'incl.'
                : partData.part_no,
              id: uuid()
            })
            // if additional items are needed, push these items to the items list
            for (let sensorNeeded of partData.need || []) {
              if (!omittedItems.includes(sensorNeeded)) {
                this.pushItem(items, sensorNeeded, inactiveSensors)
              }
            }
          }
        }

        //if dronecannon exists add psu
        if (sensors.cannons) {
          this.pushItem(items, 'dronecannon_psu', [])
        }

        // if radar brackets aren't in the ommitedArray then add them to the items list
        if (
          !omittedItems.includes('universal_mounting_system') &&
          (sensors.rf_two > 1 || sensors.radars > 1)
        )
          this.pushItem(items, 'universal_mounting_system', [])

        // add a gps module if an rf_two sensor is in the plan
        if (sensorTypes.includes('rf_two') && sensors.rf_two)
          this.pushItem(items, 'gps', inactiveSensors)

        // add a smarthub to the list if any items need it
        if (
          sensorTypes.filter(
            t => t !== 'rf_two' && t !== 'rf_zero' && t !== 'rf_patrol'
          ).length &&
          !omittedItems.includes('smarthub')
        ) {
          this.pushItem(items, 'smarthub', inactiveSensors)
          // if (CNType === 'prem' && !hasComputeNodeCase) {
          //   hasComputeNodeCase = true
          //   this.pushItem(
          //     items,
          //     `pelican_${
          //       sensorTypes.includes('cameras') && sensors.cameras ? 5 : 3
          //     }ru`,
          //     inactiveSensors
          //   )
          // } else if (sensorTypes.includes('cameras') && sensors.cameras) {
          //   this.pushItem(items, 'pelican_4ru', inactiveSensors)
          // }
        }
        if (Object.keys(sensors).some(sensor => sensor !== 'rf_patrol')) {
          // add a mast if there are 3 or less sensors
          if (mSensorsSentry <= 3) {
            this.pushItem(items, 'mast1', inactiveSensors)
          } else if (mSensorsSentry <= 7) {
            this.pushItem(items, 'mast2', inactiveSensors)
          } else {
            this.pushItem(items, 'mast2', inactiveSensors)
            this.pushItem(items, 'mast2', inactiveSensors)
          }
        }
      }

      //Add others category
      let othersCat = false
      let needsCN = false

      for (let plan of this.parsedPlan) {
        needsCN =
          Object.keys(plan).some(key => key !== 'rf_patrol') && nbSensors > 0
      }
      if (needsCN) {
        const threshold =
          CNType === 'prem' ? [10, 20, 50] : [5, 10, 15, 20, 30, 40, 50]
        const nbCN = threshold.find(n => n > nbSensors) || 50
        if (!othersCat) items.push({ name: 'OTHERS', class: 'category' })
        this.pushItem(items, `computenode_${CNType}_${nbCN}`, [
          hasComputeNode ? `computenode_${CNType}_${nbCN}` : ''
        ])
      }

      return items
    },
    parsedPlan() {
      return cloneDeep(get(this.plan, 'site_plan.installations', []))
        .map((installation: { sensors: any }) => installation.sensors)
        .map((sensors: any) => this.reduceInstallationToSensors(sensors))
    }
  },
  mounted() {
    if (this.isDRO) {
      this.headers = [
        ...this.headers,
        {
          text: 'Price (USD)',
          align: 'left',
          sortable: false,
          value: 'price'
        }
      ]
    }
  },
  watch: {
    exporting(v) {
      if (v) this.$bus.$emit('partsList', this.items)
    },
    pdfData: {
      handler: function() {
        this.$store.commit('setPdfPlanPricing', this.pdfData)
      },
      immediate: true
    }
  }
})
