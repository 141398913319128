<template>
  <d-view title="Planning tool" :bottomMargin="true">
    <template v-slot:toolbar>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <v-btn
          :disabled="!activePlan"
          flat
          icon
          @click="goToPlanLocation"
          slot="activator"
        >
          <v-icon>my_location</v-icon>
        </v-btn>
        <span>Center Map to Plan Location</span>
      </v-tooltip>
    </template>

    <template v-slot:leftpanel>
      <v-container :style="'padding: 0px'">
        <v-layout row wrap>
          <v-flex xs12>
            <plans
              ref="plans"
              :center="mapCenter"
              :zoom="mapZoom"
              :thickSectors="thickSectors"
              @thickToggle="handleThickToggle"
              @planImport="importPlan"
              @planExport="exportPlanDro"
            />
          </v-flex>
          <v-flex xs12 v-if="activePlan != null">
            <installations
              :center="mapCenter"
              :thickSectors="thickSectors"
              :clicked-position="clickedPosition"
              :active-zone="activeZone"
            />
          </v-flex>
          <zones
            v-if="activePlan"
            @activateZone="activateZone"
            @zoneEditModeRefresh="zoneEditModeRefresh"
            :active-zone="activeZone"
            :center="mapCenter"
            :shape="shape"
            class="pb-0"
          />
          <rf-performance v-if="activePlan"/>
        </v-layout>
      </v-container>
      <input
        type="file"
        ref="file"
        accept=".dro"
        style="display: none"
        @change="importPlan"
      />
    </template>

    <template v-slot:map>
      <v-container
        fill-height
        text-xs-center
        style="position: absolute; background-color:rgba(0,0,0,0.2)"
      >
        <v-layout row wrap align-center>
          <v-flex>
            <v-progress-circular
              :size="150"
              :width="4"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-flex>
        </v-layout>
      </v-container>
      <plan-map
        ref="planMap"
        @update:center="updateCenter"
        @update:zoom="updateZoom"
        :center="center"
        :zoom="mapZoom"
        v-model="shape"
        :name="name"
        :draggableSensors="thickSectors"
        :active-zone="activeZone"
        :zone-edit-mode="zoneEditMode"
        :active="true"
        :cancel-drag-handler="true"
      />
    </template>
  </d-view>
</template>

<script>
import PlanMap from '@/components/Map/PlanMap'
import { Plans, Installations, RfPerformance } from '@/components/Widgets/Plan'
import utils from '@/utils/utils'
import { mapGetters, mapActions } from 'vuex'

const Zones = () => import('@/components/Widgets/Zones')

export default {
  name: 'PlanningPage',
  components: {
    PlanMap,
    Plans,
    Installations,
    Zones,
    RfPerformance
  },
  data: () => ({
    shape: undefined,
    center: undefined,
    name: 'planMap',
    mapZoom: 16,
    mapCenter: null,
    clickedPosition: null,
    activeZone: {
      id: null,
      coordinate_list: [],
      name: ''
    },
    zoneEditMode: false,
    importedPlan: undefined,
    thickSectors: false
  }),
  computed: {
    ...mapGetters('plans', ['plansList', 'activePlan', 'plansState']),
    ...mapGetters('sites', ['activeSite']),
  },
  methods: {
    ...mapActions('plans', {
      fetchPlans: 'FETCH_PLANS',
      createPlan: 'CREATE_PLAN',
      deletePlan: 'DELETE_PLAN',
      setPlanState: 'SET_PLANS_STATE'
    }),
    handleThickToggle(v) {
      this.thickSectors = v
    },
    goToPlanLocation() {
      this.$emitter.emit('centerPlanMap', this.activePlan.site_plan.coordinates)
    },
    updateZoom(zoom) {
      if (
        !this.$refs.plans.$refs.zoom ||
        !this.$refs.plans.$refs.zoom.isFocused
      ) {
        this.mapZoom = zoom
      }
      this.setPlanState({ zoom })
    },
    updateCenter(center) {
      if (
        !this.$refs.plans.$refs.latitude ||
        (!this.$refs.plans.$refs.latitude.isFocused &&
          !this.$refs.plans.$refs.longitude.isFocused)
      ) {
        this.mapCenter = [center.lat, center.lng]
      }
      this.setPlanState({ coords: [center.lat, center.lng] })
    },
    activateZone(zone) {
      this.activeZone = zone
    },
    zoneEditModeRefresh(zoneEditMode) {
      this.zoneEditMode = zoneEditMode
    },
    exportPlanDro() {
      const dataStr =
        'data:text/json;charset=utf-8,' +
        encodeURIComponent(btoa(JSON.stringify(this.activePlan)))
      let downloadAnchorNode = document.createElement('a')
      downloadAnchorNode.setAttribute('href', dataStr)
      downloadAnchorNode.setAttribute('download', this.activePlan.name + '.dro')
      document.body.appendChild(downloadAnchorNode) // required for firefox
      downloadAnchorNode.click()
      downloadAnchorNode.remove()
    },
    async importPlan(e) {
      let file = e.target.files[0]
      this.importedPlan = await utils.readTextFile(file)
      let importJSON = JSON.parse(atob(this.importedPlan))

      // Retro compatibility (localStorage files)
      if (!importJSON.site_plan) {
        const { name, id, ...site_plan } = importJSON
        importJSON = { name, id, site_plan }
      }

      this.createPlan(importJSON)
    },
    updateMapPosition() {
      this.mapCenter = this.center = (this.activeSite &&
        (this.plansState.coords ||
          (this.activePlan && this.activePlan.site_plan.coordinates) || [
            this.activeSite.latitude,
            this.activeSite.longitude
          ])) || [-27.5060592, 136.0624544]
      this.mapZoom =
        (this.activeSite &&
          (this.plansState.zoom ||
            (this.activePlan && this.activePlan.site_plan.zoom) ||
            this.activeSite.zoom_level)) ||
        5
      this.setPlanState({ coords: this.mapCenter, zoom: this.mapZoom })
    },
    centerPlanMap({coordinates}) {
      this.mapCenter = coordinates
    }
  },
  async mounted() {
    this.updateMapPosition()
    await this.fetchPlans()
    this.$emitter.on('centerPlanMap', this.centerPlanMap)
  },
  watch: {
    plansList(v) {
      if (this.importedPlan) {
        this.$refs.plans.$emit('importPlan', v.slice(-1)[0])
        this.importedPlan = undefined
      }
    },
    activeZone() {
      this.shape = this.activeZone && this.activeZone.coordinate_list
    },
    activeSite() {
      this.mapCenter = this.center = [
        this.activeSite.latitude,
        this.activeSite.longitude
      ]
      this.mapZoom = this.activeSite.zoom_level
    }
  },
  beforeDestroy() {
    this.$emitter.off('centerPlanMap', this.centerPlanMap)
  }
}
</script>

<style scoped>
.v-speed-dial {
  position: fixed;
}
.v-speed-dial--left {
  left: unset;
}
.v-speed-dial .v-btn--outline {
  background-color: #303030 !important;
}
</style>
