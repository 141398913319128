<template>
  <v-card>
    <template v-if="editMode && !activePlan && isAuthorized('site_manager')">
      <v-layout row class="px-3 py-0">
        <v-flex class="py-0">
          <v-btn
            :data-cy="`${sentry.name}-settings-button`"
            outline
            color="primary"
            block
            small
            :to="{
              name: 'installations.edit',
              params: { id: sentry.id, sentry: sentry }
            }"
          >
            <v-icon left small> settings </v-icon>
            Edit Installation Settings
          </v-btn>
        </v-flex>
      </v-layout>
    </template>
    <radar-masks-dialog
      @onClose="onCloseSensorDialog"
      :sentry="sentry"
      :dialog="dialog === 'radarMasks'"
      :masks="editSensor && editSensor.masks"
      :sensor="editSensor"
      v-if="showRadarMaskaDialog"
    />
    <delete-sensor-dialog
      @onClose="onCloseSensorDialog"
      :sensor-type="sensorDialogType"
      :sensor="deleteSensor"
      :dialog="dialog === 'deleteSensor'"
      v-if="showDeleteSensorDialog"
    />
    <restart-sensor-dialog
      @onClose="onCloseSensorDialog"
      :sensor-type="sensorDialogType"
      :dialog="dialog === 'restartSensor'"
      v-if="showRestartSensorDialog"
    />
    <sensor-group-card
      v-for="key in groupKeys"
      :title="key"
      :edit-mode="editMode"
      :sensors="sensors[key]"
      :sentry="sentry"
      :key="`sensor-group-${key}`"
      :cameraPage="cameraPage"
      @onClickRadarMasks="onClickRadarMasks"
      @onClickCameraDroneCalibration="onClickCameraDroneCalibration"
      @onClickCameraBracketCalibration="onClickCameraBracketCalibration"
      @onClickRfFilters="onClickRfFilters"
      @onClickEditSensor="onClickEditSensor"
      @onClickDeleteSensor="onClickDeleteSensor"
      @onClickNewSensor="onClickNewSensor"
      @onClickRestartSensor="onClickRestartSensor"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
const RadarMasksDialog = () => import('@/components/Dialogs/RadarMasks')
const DeleteSensorDialog = () => import('@/components/Dialogs/DeleteSensor')
const RestartSensorDialog = () => import('@/components/Dialogs/RestartSensor')
const SensorGroupCard = () => import('./SensorGroups/SensorGroupCard')

const props = {
  editMode: {
    type: Boolean,
    default: false
  },
  sentry: {
    type: Object
  },
  cameraPage: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'SentryCard',
  components: {
    RadarMasksDialog,
    SensorGroupCard,
    DeleteSensorDialog,
    RestartSensorDialog
  },
  props,
  data: () => ({
    dialog: null,
    // undefined required by vue in order to trigger props default
    sensorDialogType: undefined,
    editSensor: undefined,
    deleteSensor: undefined,
    groups: {
      Detection: ['radars', 'rf_sensors', 'cameras', 'dsx_sensors'],
      Response: ['cannons'],
      System: ['gps_compasses']
    },
    conversionTable: {
      radars: 'radar',
      rf_sensors: 'rf',
      cannons: 'cannon',
      gps_compasses: 'compass',
      cameras: 'camera',
      dsx_sensors: 'dronesentryx'
    },
    restartConversionTable: {
      radars: 'radars',
      rf_sensors: 'rf_sensors',
      cannons: 'cannons',
      cameras: 'cameras'
    },
    showRadarMaskaDialog: false,
    showRfFiltersDialog: false,
    showDeleteSensorDialog: false,
    showRestartSensorDialog: false
  }),
  computed: {
    ...mapGetters('sensors', { getSensors: 'allSensorsInSentry' }),
    ...mapGetters('cameras', ['allCamerasInSentry']),
    ...mapGetters('rf_sensors', ['allRfSensorsInSentry', 'allDsxSensorsInSentry']),
    ...mapGetters('cannons', ['allCannonsInSentry']),
    ...mapGetters('radars', ['allRadarsInSentry']),
    ...mapGetters('plans', ['activePlan']),
    ...mapGetters('users', ['isAuthorized']),
    ...mapState('system', ['defeat_enabled', 'tactical']),
    groupKeys() {
      return Object.keys(this.groups)
    },
    allSensors() {
      let sensors = {
        rf_sensors: this.allRfSensorsInSentry(this.sentry.id),
        dsx_sensors: this.allDsxSensorsInSentry(this.sentry.id),
      }

      if (!this.tactical) {
        sensors = {
          ...sensors,
          cameras: this.allCamerasInSentry(this.sentry.id),
          radars: this.allRadarsInSentry(this.sentry.id),
          gps_compasses: this.getSensors(this.activePlan ? null : this.sentry).gps_compasses
        }

        if (this.defeat_enabled) {
          sensors = {...sensors, cannons: this.allCannonsInSentry(this.sentry.id) }
        }
      }

      return sensors
    },
    sensors() {
      let allSensors = this.activePlan ? Object.assign({
        radars: [],
        rf_sensors: [],
        cameras: [],
        dsx_sensors: [],
        cannons: [],
        gps_compasses: []
      }, this.sentry.sensors) : this.allSensors
      const sensors = {}
      Object.keys(allSensors).forEach((sensorType) => {
        Object.keys(this.groups).forEach((group) => {
          if (this.groups[group].includes(sensorType)) {
            const item = {}
            item[sensorType] = allSensors[sensorType]
            sensors[group] = {
              ...sensors[group], ...item
            }
          }
        })
      })
      return sensors
    }
  },
  methods: {
    ...mapActions('cameras', ['CAMERA_CONTROL']),
    onClickNewSensor(sensorType) {
      // this.dialog = 'editSensor'
      // this.editSensor = {}
      // this.sensorDialogType = this.conversionTable[sensorType]
      this.$store.commit('setActiveGroup', { [sensorType]: true })
      return this.$router.push({
        name: 'sensors.new',
        params: { sensorType: this.conversionTable[sensorType] }
      })
    },
    onClickRestartSensor({ sensorType }) {
      this.dialog = 'restartSensor'
      this.sensorDialogType = this.restartConversionTable[sensorType]
      this.showRestartSensorDialog = true
    },
    onClickEditSensor({ sensorType, sensor }) {
      this.$router.push({
        name: 'sensors.edit',
        params: { sensorType: this.conversionTable[sensorType], id: sensor.id }
      })
    },
    onClickDeleteSensor({ sensorType, sensor }) {
      this.dialog = 'deleteSensor'
      this.deleteSensor = sensor
      this.sensorDialogType = this.conversionTable[sensorType]
      this.showDeleteSensorDialog = true
    },
    onCloseSensorDialog() {
      this.dialog = null
      this.deleteSensor = undefined
      this.sensorDialogType = undefined
      this.editSensor = undefined
      this.showDeleteSensorDialog = false
      this.showRfFiltersDialog = false
      this.showRadarMaskaDialog = false
      this.showRestartSensorDialog = false
    },
    onClickRadarMasks(sensor) {
      this.dialog = 'radarMasks'
      this.editSensor = sensor
      this.showRadarMaskaDialog = true
    },
    onClickCameraDroneCalibration(camera) {
      this.$router.push({
        name: 'sensors.edit',
        params: {
          sensorType: 'camera',
          id: camera.id,
          parameters: { calibrate: true }
        }
      })
    },
    async onClickCameraBracketCalibration(camera) {
      this.CAMERA_CONTROL({
        camera_id: camera.id,
        command: 'calibrateCameraBracket',
        action: 'start'
      })
    },
    onClickRfFilters(sensor) {
      this.dialog = 'rfFilters'
      this.editSensor = sensor
      this.showRfFiltersDialog = true
    }
  },
  created() {
    if (this.activePlan) delete this.groups.System
    if (this.cameraPage)
      this.groups = {
        Detection: ['cameras']
      }
  }
}
</script>
